.container {
    height: 54px;
    color: #515359;
    cursor: pointer;
    padding: 0 20px;

    box-shadow: inset 0px -1px 0px 0px #F0F2F5;
}

.image {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin-right: 8px;
}
