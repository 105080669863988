.scroll{
    over-flow: auto;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    overflow-y: auto;
}

.scrollX{
    @extend .scroll;
    overflow-y:hidden;
    overflow-x: auto;
}
