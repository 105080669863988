$primary-color: #FA541C;
$primary-hover-color: #FF7A45;
$active-color: #AD2102;
$default-color: rgba(0, 0, 0, 0.85);

$chinesePriceUnit: '\A5';
$chineseSubPriceUnit: '-'+$chinesePriceUnit;

$iconFontName: 'iconfont';
$cubicBezier: cubic-bezier(.645, .045, .355, 1);

@mixin FontFaceSource {
    src: url('//at.alicdn.com/t/font_592266_4joqjs5ar48.woff2?t=1631610598826') format('woff2'),
    url('//at.alicdn.com/t/font_592266_4joqjs5ar48.woff?t=1631610598826') format('woff'),
    url('//at.alicdn.com/t/font_592266_4joqjs5ar48.ttf?t=1631610598826') format('truetype');
}

@mixin CommonTransition($type:all,$s:0.3) {

    $content: '';

    @each $oneType in $type {

        @if ($content != '') {
            $content: $content, $oneType #{$s}s #{$cubicBezier}
        } @else {
            $content: $oneType #{$s}s #{$cubicBezier}
        }
    }

    transition: $content;
}

@mixin FontFace {
    @font-face {
        font-family: $iconFontName;  /* project id 592266 */
        @include FontFaceSource();
    }
}

@mixin IconFontName($fontSize:false) {
    font-family: $iconFontName;
    @if ($fontSize != false) {
        font-size: $fontSize;
    }
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}
