.container{
    display: flex;
}

.autoList {
    flex: 1;
    height: 520px;
}

.navWrap {
    height: 520px;
    width: 30px;
    box-shadow: inset 1px 0px 0px 0px #F0F2F5;
}

.navItem {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;

    font-size: 12px;
}


