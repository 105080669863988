.container {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.title {
    margin: 0.5em 12px;
    min-width: 74px;
}

.headerBox {
    display: flex;
    align-items: center;
    height: 74px;
}

.logo {
    width: 50px;
    height: 50px;
    border-radius: 2px;
}


.navBtn {
    color: #515359;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    margin-left: 40px;
}

.endBox{
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.input{
    width: 260px;
}
