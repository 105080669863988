.container{
    display: inline-flex;
    align-items: center;
    height: 24px;
    padding: 0 8px;

    font-size: 12px;
    color: #84868C;
    background: #F0F2F5;
    border-radius: 2px;
}
