.container {
    height: 430px;
    position: relative;
    padding-bottom: 56px;
}


.content {
    height: (400px - 56px);
}


.actionBox {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0 24px;
    box-shadow: 0px -2px 8px 0px rgba(167, 178, 204, 0.1), inset 0px 1px 0px 0px #F0F2F5;
}

.autoItem {
    position: relative;
    padding: 0 24px;
    box-shadow: inset 0px -1px 0px 0px #F0F2F5;

    &.active {
        background-color: #FFF2E8;
    }

    .icon {
        position: absolute;
        top: 20px;
        right: 10px;
    }
}

.autoNameWrap {
    padding: 10px;
}

.autoName {
    color: #515359;
}

.autoName2 {
    display: flex;
    align-items: center;
    color: #515359;
    height: 40px;
    margin-bottom: 8px;
}

.autoSeries {
    font-size: 12px;
    color: #84868C;
}
