.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img {
    width: 80px;
    height: 80px;
}


.largeImage {
    width: 120px;
    height: 120px;
}

.title {
    margin-top: 24px;
}

.largeTitle {
    font-size: 16px;
    @extend .title;
}

.content {
    color: #84868C;
    margin-top: 12px;
}

.children {
    margin-top: 24px;
}
